exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-collections-compare-tsx": () => import("./../../../src/pages/collections-compare.tsx" /* webpackChunkName: "component---src-pages-collections-compare-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-object-tsx": () => import("./../../../src/templates/object.tsx" /* webpackChunkName: "component---src-templates-object-tsx" */),
  "component---src-templates-standard-tsx": () => import("./../../../src/templates/standard.tsx" /* webpackChunkName: "component---src-templates-standard-tsx" */)
}

